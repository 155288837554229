import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['listView', 'mainView', 'detailView', 'mapView', 'detailContent'];

  connect() {
    this.selectedItem = null;
  }

  showDetails(event) {
    const itemElement = event.currentTarget;

    // Remove highlight from previously selected item
    if (this.selectedItem) {
      this.selectedItem.classList.remove('bg-primary-200');
    }

    // Add highlight to current item
    itemElement.classList.add('bg-primary-200');
    this.selectedItem = itemElement;

    // Show detail view and adjust layout
    if (this.detailViewTarget.classList.contains('hidden')) {
      this.detailViewTarget.classList.remove('hidden');
    }
    this.mapViewTarget.classList.contains('flex-grow') &&
      this.mapViewTarget.classList.remove('flex-grow');
    !this.mapViewTarget.classList.contains('w-1/2') && this.mapViewTarget.classList.add('w-1/2');

    // Update detail content (replace with actual data fetching logic)

    // You might want to update the map view here as well
    // For example, center the map on the selected item
  }

  closeDetails() {
    // Remove highlight from selected item
    if (this.selectedItem) {
      this.selectedItem.classList.remove('bg-primary-200');
      this.selectedItem = null;
    }

    // Hide detail view and restore original layout
    this.detailViewTarget.classList.add('hidden');
    this.mapViewTarget.classList.add('flex-grow');
    this.mapViewTarget.classList.remove('w-1/2');

    // Reset detail content
    this.detailContentTarget.textContent = 'Select an item to see details.';

    // You might want to reset the map view here as well
    // For example, show all items on the map
  }
}
