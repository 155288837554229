import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['node', 'toggleButton', 'icon', 'childList'];

  connect() {
    this.initializeNodes();
  }

  initializeNodes() {
    this.nodeTargets.forEach((node) => {
      const childList = node.querySelector('[data-rp-list-target="childList"]');
      const toggleButton = node.querySelector('[data-rp-list-target="toggleButton"]');
      const isExpanded = node.hasAttribute('expanded');
      if (childList && childList.children.length === 0) {
        toggleButton.classList.add('invisible');
      } else {
        this.setExpandedState(node, isExpanded || false);
      }
    });
  }

  toggle(event) {
    event.preventDefault();
    const node = event.currentTarget.closest('[data-rp-list-target="node"]');
    const isExpanded = node.dataset.expanded === 'true';
    this.setExpandedState(node, !isExpanded);
  }

  setExpandedState(node, isExpanded) {
    const childList = node.querySelector('[data-rp-list-target="childList"]');
    const icon = node.querySelector('[data-rp-list-target="icon"]');

    node.dataset.expanded = isExpanded;
    childList.classList.toggle('hidden', !isExpanded);
    icon.style.transform = isExpanded ? 'rotate(90deg)' : '';

    node.classList.toggle('border-l-2', isExpanded);
    node.classList.toggle('border-primary', isExpanded);
  }
}
