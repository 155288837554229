import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'primaryContainer',
    'secondaryContainer',
    'detailView',
    'detailContent',
    'initialMessage',
  ];

  showDetails(event) {
    const itemID = event.currentTarget.dataset.itemId;

    // Hide initial message and show detail view
    this.initialMessageTarget.classList.add('hidden');
    this.detailViewTarget.classList.remove('hidden');

    // Update detail content (replace with actual data fetching logic)
    this.detailContentTarget.textContent = `Detailed information for Item ${itemID}.`;

    // Optionally, you could add a selected state to the clicked list item
    this.primaryContainerTarget
      .querySelectorAll("[data-action='click->rp-two-col-ui#showDetails']")
      .forEach((el) => el.classList.remove('bg-blue-600'));
    event.currentTarget.classList.add('bg-blue-600');
  }

  closeDetails() {
    // Hide detail view and show initial message
    this.detailViewTarget.classList.add('hidden');
    this.initialMessageTarget.classList.remove('hidden');

    // Reset detail content
    this.detailContentTarget.textContent = 'Loading item details...';

    // Remove selected state from list items
    this.primaryContainerTarget
      .querySelectorAll("[data-action='click->rp-two-col-ui#showDetails']")
      .forEach((el) => el.classList.remove('bg-blue-600'));
  }
}
