import { Controller } from '@hotwired/stimulus';
import { Area } from '@antv/g2plot';

export default class extends Controller {
  static values = {
    dataUrl: String,
  };

  connect() {
    const rootStyles = getComputedStyle(document.documentElement);
    this.primaryColor = rootStyles.getPropertyValue('--rp-color-primary');
    this.primaryColorActive = rootStyles.getPropertyValue('--rp-color-primary-active');
    this.fetchData();
  }

  fetchData() {
    const url = this.dataUrlValue;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        this.renderSensors(data);
      })
      .catch((error) => console.error('Error fetching sensor data:', error));
  }

  renderSensors(data) {
    const container = this.element;

    container.innerHTML = '';

    if (data.temperature && data.temperature.length > 0) {
      this.insertSensorSection(
        container,
        'temperature',
        data.temperature,
        '°C',
        'Temperature',
        'temperature.svg',
      );
      this.renderChart('temperature-container', data.temperature, '°C', 'Temperature');
    }

    // Render Battery Section
    if (data.battery && data.battery.length > 0) {
      this.insertSensorSection(container, 'battery', data.battery, '%', 'Battery', 'battery.svg');
      this.renderChart('battery-container', data.battery, '%', 'Battery');
    }

    // Render Light Section
    if (data.light && data.light.length > 0) {
      this.insertSensorSection(container, 'light', data.light, 'lx', 'Light', 'light.svg');
      this.renderChart('light-container', data.light, 'lx', 'Light');
    }

    if (!data.light.length && !data.battery.length && !data.temperature.length) {
      const noDataHTML = `
            <div class="w-full flex items-center mt-10 flex-wrap justify-center">
            <div class="flex flex-col items-center gap-4">
              <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                <title>Simple Empty</title>
                <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                  <ellipse fill="#f5f5f5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                  <g fill-rule="nonzero" stroke="#d9d9d9">
                    <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                    <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#fafafa"></path>
                  </g>
                </g>
              </svg>
              <div>
                <h2 class="text-center text-gray-400 text-[14px] leading-relaxed pb-1 -mt-2">No Data</h2>
              </div>
            </div>
          </div>
`;
      container.innerHTML = noDataHTML;
    }
  }

  insertSensorSection(container, sensorType, sensorData, unit, label, icon) {
    const latestValue = sensorData[sensorData.length - 1]?.value || 'N/A';
    const minValue = Math.min(...sensorData.map((d) => d.value));
    const maxValue = Math.max(...sensorData.map((d) => d.value));

    const sensorHtml = `
      <div class="w-full border-2 rounded-lg flex flex-col">
        <div class="p-4 border-b flex justify-between items-center">
          <div class="flex font-semibold items-center text-2xl gap-x-2">
            <img src="core/icons/${icon}" alt="${label} Icon" class="w-8 h-8 inline-block text-primary">
            <span>${latestValue} ${unit}</span>
          </div>
          <div class="flex gap-x-6 text-[14px] font-medium items-center">
            <div class="flex flex-col items-center">
              <span class="text-gray-500 font-normal">Min</span>
              ${minValue} ${unit}
            </div>
            <div class="flex flex-col items-center">
              <span class="text-gray-500 font-normal">Max</span>
              ${maxValue} ${unit}
            </div>
          </div>
        </div>
        <div id="${sensorType}-container" class="border-b"></div>
      </div>
    `;

    container.insertAdjacentHTML('beforeend', sensorHtml);
  }

  renderChart(containerId, data, unit, label) {
    const chart = new Area(document.getElementById(containerId), {
      data: data,
      xField: 'timestamp',
      yField: 'value',
      xAxis: {
        type: 'time',
        mask: 'YYYY-MM-DD HH:mm:ss',
      },
      color: `${this.primaryColor}`,
      yAxis: {
        label: {
          formatter: (val) => `${val} ${unit}`,
        },
      },
      areaStyle: () => {
        return {
          fill: `l(270) 0:#ffffff 1:${this.primaryColor}`,
        };
      },
      point: {
        shape: 'circle',
        style: {
          r: 4,
          stroke: '',
        },
      },
      slider: {
        foregroundStyle: {
          fill: `${this.primaryColor}`,
        },
        start: 0.5,
        end: 1,
        handlerStyle: {
          fill: `${this.primaryColor}`,
          highLightFill: `${this.primaryColorActive}`,
          stroke: `#ffffff`,
        },

        height: 20,
        minLimit: 0.5,
        maxLimit: 1,
        trendCfg: {
          isArea: true,
          smooth: true,
          color: `${this.primaryColor}`,
        },
        formatter: (timestamp) => {
          const date = new Date(timestamp);
          return date.toDateString();
        },
      },
    });
    chart.render();
  }
}
