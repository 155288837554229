import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'passwordField',
    'passwordConfirmField',
    'lengthIcon',
    'uppercaseIcon',
    'lowercaseIcon',
    'numberIcon',
    'specialCharIcon',
    'submit',
    'passwordToggleIcon',
    'passwordConfirmToggleIcon',
  ];

  connect() {
    if (this.isTargetsAvailable()) {
      this.updateIcons();
      this.toggleSubmitButton();
    }
  }

  isTargetsAvailable() {
    if (
      this.hasLengthIconTarget &&
      this.hasUppercaseIconTarget &&
      this.hasLowercaseIconTarget &&
      this.hasNumberIconTarget &&
      this.hasSpecialCharIconTarget
    ) {
      return true;
    }
    return false;
  }

  checkStrength() {
    this.updateIcons();
    this.toggleSubmitButton();
  }

  updateIcons() {
    const password = this.passwordFieldTarget.value;

    this.checkLength(password);
    this.checkUppercase(password);
    this.checkLowercase(password);
    this.checkNumber(password);
    this.checkSpecialChar(password);
  }

  togglePasswordVisibility(event) {
    if (event.currentTarget === this.passwordToggleIconTarget) {
      this.toggleFieldVisibility(this.passwordFieldTarget, this.passwordToggleIconTarget);
    } else if (event.currentTarget === this.passwordConfirmToggleIconTarget) {
      this.toggleFieldVisibility(
        this.passwordConfirmFieldTarget,
        this.passwordConfirmToggleIconTarget,
      );
    }
  }

  toggleFieldVisibility(field, icon) {
    const isPassword = field.type === 'password';
    field.type = isPassword ? 'text' : 'password';

    icon.innerHTML = isPassword
      ? `<svg class="w-5 h-5 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M13.875 18.825c-3.9 0-7.275-2.745-8.625-6.825 1.35-4.08 4.725-6.825 8.625-6.825 3.9 0 7.275 2.745 8.625 6.825-1.35 4.08-4.725 6.825-8.625 6.825z" />
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 15a3 3 0 0 1 0-6 3 3 0 0 1 0 6z" />
      </svg>`
      : `<svg class="w-5 h-5 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
        <path stroke-linecap="round" stroke-linejoin="round" d="M2.458 12C3.732 7.943 7.532 5 12 5c4.467 0 8.267 2.943 9.542 7-1.275 4.057-5.075 7-9.542 7-4.467 0-8.267-2.943-9.542-7z" />
      </svg>`;
  }

  toggleSubmitButton() {
    const isValid =
      this.lengthIconTarget.classList.contains('rp-success') &&
      this.uppercaseIconTarget.classList.contains('rp-success') &&
      this.lowercaseIconTarget.classList.contains('rp-success') &&
      this.numberIconTarget.classList.contains('rp-success') &&
      this.specialCharIconTarget.classList.contains('rp-success');

    if (this.submitTarget) {
      this.submitTarget.disabled = !isValid;
      this.submitTarget.classList.toggle('rp-btn-disabled', !isValid);
      this.submitTarget.classList.toggle('rp-btn-enabled', isValid);
    }
  }

  checkLength(password) {
    const minLength = 8;
    const lengthMet = password.length >= minLength;
    this.lengthIconTarget.classList.toggle('rp-success', lengthMet);
    this.lengthIconTarget.classList.toggle('rp-failure', !lengthMet);
  }

  checkUppercase(password) {
    const uppercaseMet = /[A-Z]/.test(password);
    this.uppercaseIconTarget.classList.toggle('rp-success', uppercaseMet);
    this.uppercaseIconTarget.classList.toggle('rp-failure', !uppercaseMet);
  }

  checkLowercase(password) {
    const lowercaseMet = /[a-z]/.test(password);
    this.lowercaseIconTarget.classList.toggle('rp-success', lowercaseMet);
    this.lowercaseIconTarget.classList.toggle('rp-failure', !lowercaseMet);
  }

  checkNumber(password) {
    const numberMet = /[0-9]/.test(password);
    this.numberIconTarget.classList.toggle('rp-success', numberMet);
    this.numberIconTarget.classList.toggle('rp-failure', !numberMet);
  }

  checkSpecialChar(password) {
    const specialCharMet = /[!@#?]/.test(password);
    this.specialCharIconTarget.classList.toggle('rp-success', specialCharMet);
    this.specialCharIconTarget.classList.toggle('rp-failure', !specialCharMet);
  }
}
