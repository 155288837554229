import '@hotwired/turbo-rails';
import '@hotwired/strada';
import { Application } from '@hotwired/stimulus';
import 'flowbite/dist/flowbite.turbo.js';

import * as Controllers from './controllers';

const application = Application.start();

application.register('rp-common', Controllers.RPCommon);
application.register('rp-list', Controllers.RPList);
application.register('rp-select', Controllers.RPSelect);
application.register('rp-date', Controllers.RPDate);
application.register('rp-filters', Controllers.RPFilters);
application.register('rp-three-col-ui', Controllers.RPThreeColumn);
application.register('rp-two-col-ui', Controllers.RPTwoColumn);
application.register('rp-password', Controllers.RPPassword);
application.register('rp-sensor-chart', Controllers.RPSensorChart);
