import { Controller } from '@hotwired/stimulus';
import { easepick, RangePlugin } from '@easepick/bundle';

export default class extends Controller {
  static targets = ['input', 'clearButton', 'startDate', 'endDate'];

  connect() {
    this.initializeDatePicker();
    this.inputTarget.classList.add('rp-input');
  }

  initializeDatePicker() {
    const options = {
      element: this.inputTarget,
      css: ['https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css'],
      plugins: [RangePlugin],
      RangePlugin: {
        tooltip: true,
      },
      format: 'YYYY-MM-DD',
      grid: 2,
      calendars: 2,
      setup: (picker) => {
        picker.on('select', (e) => {
          this.updateDates(e.detail.start, e.detail.end);
          this.toggleClearButton();
        });
      },
    };

    this.picker = new easepick.create(options);
    this.toggleClearButton();
  }

  clearDateRange() {
    if (this.picker) {
      this.picker.clear();
      this.inputTarget.value = '';
      this.updateDates(null, null);
      this.toggleClearButton();
    }
  }

  toggleClearButton() {
    if (this.hasClearButtonTarget) {
      this.clearButtonTarget.classList.toggle('hidden', !this.inputTarget.value);
    }
  }

  updateDates(start, end) {
    if (this.hasStartDateTarget) {
      this.startDateTarget.value = start.format('YYYY-MM-DD');
    }
    if (this.hasEndDateTarget) {
      this.endDateTarget.value = end.format('YYYY-MM-DD');
    }
    // Dispatch a custom event with the selected date range
    this.element.dispatchEvent(
      new CustomEvent('dateRangeSelected', {
        detail: { start, end },
        bubbles: true,
      }),
    );
  }

  disconnect() {
    if (this.picker) {
      this.picker.destroy();
    }
  }
}
