import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['extraFilters'];

  toggle(event) {
    event.preventDefault();
    this.extraFiltersTarget.classList.toggle('is-open');
  }
}
