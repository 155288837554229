import { Controller } from '@hotwired/stimulus';
import { generate } from '@ant-design/colors';

export default class extends Controller {
  connect() {
    const root = document.querySelector(':root');
    const host = document.querySelector(':host');

    if (window.gon.primary_color) {
      const primaryColors = generate(window.gon.primary_color);

      // Configure primary colors
      root.style.setProperty('--rp-color-primary-bg', primaryColors[0]);
      root.style.setProperty('--rp-color-primary-bg-hover', primaryColors[1]);
      root.style.setProperty('--rp-color-primary-border', primaryColors[2]);
      root.style.setProperty('--rp-color-primary-border-hover', primaryColors[3]);
      root.style.setProperty('--rp-color-primary-hover', primaryColors[4]);
      root.style.setProperty('--rp-color-primary', primaryColors[5]);
      root.style.setProperty('--rp-color-primary-active', primaryColors[6]);
    }

    if (window.gon.success_color) {
      const successColors = generate(window.gon.success_color);

      // Configure success colors
      root.style.setProperty('--rp-color-success-bg', successColors[0]);
      root.style.setProperty('--rp-color-success-bg-hover', successColors[1]);
      root.style.setProperty('--rp-color-success-border', successColors[2]);
      root.style.setProperty('--rp-color-success-border-hover', successColors[3]);
      root.style.setProperty('--rp-color-success-hover', successColors[4]);
      root.style.setProperty('--rp-color-success', successColors[5]);
      root.style.setProperty('--rp-color-success-active', successColors[6]);
    }

    if (window.gon.warning_color) {
      const warningColor = generate(window.gon.warning_color);

      // Configure warning colors
      root.style.setProperty('--rp-color-warning-bg', warningColor[0]);
      root.style.setProperty('--rp-color-warning-bg-hover', warningColor[1]);
      root.style.setProperty('--rp-color-warning-border', warningColor[2]);
      root.style.setProperty('--rp-color-warning-border-hover', warningColor[3]);
      root.style.setProperty('--rp-color-warning-hover', warningColor[4]);
      root.style.setProperty('--rp-color-warning', warningColor[5]);
      root.style.setProperty('--rp-color-warning-active', warningColor[6]);
    }

    if (window.gon.danger_color) {
      const dangerColor = generate(window.gon.danger_color);

      // Configure danger colors
      root.style.setProperty('--rp-color-danger-bg', dangerColor[0]);
      root.style.setProperty('--rp-color-danger-bg-hover', dangerColor[1]);
      root.style.setProperty('--rp-color-danger-border', dangerColor[2]);
      root.style.setProperty('--rp-color-danger-border-hover', dangerColor[3]);
      root.style.setProperty('--rp-color-danger-hover', dangerColor[4]);
      root.style.setProperty('--rp-color-danger', dangerColor[5]);
      root.style.setProperty('--rp-color-danger-active', dangerColor[6]);
    }
  }

  disconnect() {}
}
