import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'hiddenInput', 'dropdown', 'option'];

  connect() {
    this.updateDisplayFromValue();
  }

  disconnect() {
    this.close();
  }

  toggleDropdown(event) {
    this.element.classList.toggle('active');
    this.dropdownTarget.classList.toggle('active');
  }

  onChange(event) {
    this.element.submit();
  }

  selectOption(event) {
    const selectedOption = event.currentTarget;
    this.inputTarget.value = selectedOption.textContent;
    this.hiddenInputTarget.value = selectedOption.dataset.value;
    this.element.classList.remove('active');
    this.dropdownTarget.classList.remove('active');
    this.optionTargets.forEach((opt) => opt.classList.remove('rp-selected'));
    selectedOption.classList.add('rp-selected');
  }

  clickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.close();
    }
  }

  close() {
    this.element.classList.remove('active');
    this.dropdownTarget.classList.remove('active');
  }

  updateDisplayFromValue() {
    const value = this.hiddenInputTarget.value;
    if (value) {
      const selectedOption = this.optionTargets.find((opt) => opt.dataset.value === value);
      if (selectedOption) {
        this.inputTarget.value = selectedOption.textContent;
        selectedOption.classList.add('rp-selected');
      }
    }
  }
}
